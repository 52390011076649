<template>

        <div>
          <Div id="motor-list">
          <div class="options w-100 py-2 mt-2">
            <div class="d-inline-block text-center">
              <router-link :to="'/main/motors/'">
                <div class="icon mx-2">
                  <!--<span class="ukazatel-alertu" v-if="pocetVystrah>0">{{pocetVystrah}}</span>-->
                  <div class="active-line"></div>
                  <img src="/img/moje_karty.png" alt />
                </div>
                <div class="mt-1">{{$t('option.moje-karty')}}</div>
              </router-link>
            </div>

            <div class="d-inline-block text-center">
              <router-link to="/groups">
                <div class="icon mx-2">

                  <div class="active-line"></div>
                  <img src="/img/group-50.png" alt />
                </div>
                <div class="mt-1">{{$t('menu.team')}}</div>

              </router-link>

            </div>
             </div>

          </Div>





<!--

            <div id="team-list">
                <div class="options w-100 py-2 mt-2">
                    <div class="d-inline-block text-center">
                        <a @click.prevent="vkladameSkupinu=true">
                            <div class="icon mx-2">
                                <div class="active-line"></div>
                                <img src="/img/pridat.png" alt />
                            </div>
                            <div class="mt-1">{{$t('groups.create')}}</div>
                        </a>
                    </div>
                </div>
            </div>
-->

          <div class="mt-3 row" >

            <router-link :to="`/company`">
              <div class="group d-inline-block text-center">
                <div class="icon mx-2">
                  <img src="/img/group-50.png" alt style=""/>
                </div>
                <div><strong>{{me2.company.name}}</strong></div>

              </div>
            </router-link>
          </div>
 <div class="hr"></div>


          <div class="d-flex pt-2 pl-2 submenu2">
            <a :class="'mx-2 '+(activetab==1 ? 'active':'')" @click.prevent="btn_prehled" href="#" >{{$t('groups.subnemu.prehled')}}</a>
            <a v-if="me2.role>2" :class="'mx-2 '+(activetab==2 ? 'active':'')" @click.prevent="vkladameSkupinu=true; activetab=2"  href="#">{{$t('groups.create')}}</a>
          </div>


            <div class="list w-100 py-2 px-1" v-if="vkladameSkupinu">

                    <div class="row">
                        <div class="col col-8 col-lg-5">

                            <div class="form-group mb-2 ">
                                <label for="inputEmail">{{$t('groups.new-group-name')}}</label>
                                <input id="inputEmail" type="text" class="form-control form-control-sm"
                                    v-model.trim="newgroup"   />
                            </div>

                            <button class="btn btn-success mb-2" @click="addGroup">{{$t('odeslat')}}</button>
                            <div>{{msg}}</div>

                        </div>
                    </div>
            </div>


            <div class="mt-3 row" v-if="accounts.length>0">

                <router-link :to="`/team/${a.id}`"  v-for="a in accounts" :key="a.id" >
                <div class="group d-inline-block text-center">
                    <div class="icon mx-2">
                        <img src="/img/group-50.png" alt style=""/>
                    </div>
                    <div><strong>{{a.name}}</strong></div>
                  <a class="destroygroup" href="#" @click.prevent="removeGroup(a.id)"><i class="fas fa-times"></i></a>
                </div>
                </router-link>
            </div>

        </div>

</template>

<script>
    /*
    4-A tady přichází ta hlavní změna. Momentálně máme v hlavičce dlaždice naše motory, členové teamu atd.
     Tyto dlaždice nahradíme jednou dlaždicí s názvem Vytvořit skupinu a do ní dáme velké plus.
     Když na tut dlaždici kliknu nabídne se mi textové pole s výzvou k pojmenování skupiny.
     jakmile pojmenuji skupinu a vyberu uložit, založí se mi dlaždice na nižší úrovni.
     Tam kde je nyní Členové firemního teamu. Do dlaždice dáme nějakou ikonu skupiny.
      Když pak na tuto dlaždici kliknu otevře se mi stránka skupiny. tady uvidím vrchní lištu z dlaždicemi, členové (již máme), přidat člena, hledat člena, Pozvání. Vše už máme.
    5- V dalším kole bych chtěl, aby skupina mohla mít ještě podskupinu. Je to z důvodu, kdy firma vlastní několik výrobních závodů, které chce samostatně vyhodnocovat.
    6-Pokud by uživatel, který se přihlásil pomocí osobního účtu chtěl vytvořit skupinu, bude vyzván k založení firemního účtu. jinak to nepůjde,
    To je vše
    */
import userMixin from "@/mixins/userMixin";
    const axios = require("axios");
    axios.defaults.withCredentials = true
    export default {
        mixins: [userMixin],

        name: "Groups",
        data(){
            return {
                invitResult:'',
                newgroup:'' ,
                accounts:[],
                msg:'',
                vkladameSkupinu:false,
              activetab:1
            }
        },
        mounted() {
             this.refreshAccounts()
          this.vkladameSkupinu=false
        },
        methods:{
          btn_prehled(){

            this.vkladameSkupinu=false;
            this.activetab=1

          },

          removeGroup(group_id){

            if (!confirm(this.$t('groups.remove-confirmation'))){
              return
            }



            axios.delete( process.env.VUE_APP_API_ROOT_URL + `/account/${group_id}`).then(result => {
                      if (result.status>=200 && result.status<300){
                        for(var i=0; i<this.accounts.length; i++){
                          if (this.accounts[i].id == group_id){
                            this.accounts.splice(i,1)
                          }
                        }
                      }
            })

          },


            addGroup(){

                axios
                    .post( process.env.VUE_APP_API_ROOT_URL + "/account?t="+ new Date().getTime(), { name: this.newgroup })
                    .then(result => {
                        //console.log('reg', result.status, result.data)
                        if (result.status == 201 && result.data.id > 0) {
                            this.msg = "Firma vytvorena";
                            this.refreshAccounts()
                        }else{
                            if (result.data.errors){
                                this.msg = this.expandErrors(result.data.errors)
                            }
                        }
                    })
                    .catch(err => {
                        this.msg = this.expandErrors(err.response.data.errors)

                    });

            },
            expandErrors(err){
                let m=''
                for(let k in err){
                    m+= k+ " "+ err[k]
                }
                return m
            },
            refreshAccounts(){
                axios.get(
                    process.env.VUE_APP_API_ROOT_URL + "/accounts?t="+ new Date().getTime() )
                    .then(result => {
                        this.accounts = result.data

                    })


            }



        }
    }
</script>

<style scoped lang="scss">


    .group{
        margin:15px;
        background: white;
        width: 150px;
        height:150px;
        border:1px solid #ddd;
        .icon{
        padding-top:20px;
            margin-bottom: 10px;
                }
      position: relative;
    }


    .destroygroup{
      color:black;
      position:absolute;
      top:10px;
      right:10px;
      &:hover{
        color: #fab20a
      }
    }

    .hr{
      border-bottom:1px solid #fab20a;
    }

</style>
